import React, {useState} from 'react';
import * as PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Form from '../../form/components/Form';
import { IdPropType } from '../../../proptypes/basic';
import SubmitButton from '../../form/components/SubmitButton';
import TransferStatusSelect, {
    TRANSFER_STATUS_OPTIONS,
} from '../../form/components/specialized/TransferStatusSelect';
import DateInput from '../../form/components/DateInput';
import TextInput from '../../form/components/TextInput';
import transportationChoices from '../../../config/nurseTransferTransportationMeans';
import Select from '../../form/components/Select';
import Checkbox from '../../form/components/Checkbox';
import TransferDestinationSelect from '../../form/components/specialized/TransferDestinationSelect';
import ConditionalField from '../../form/components/specialized/ConditionalField';
import TransferPickupSelect from '../../form/components/specialized/TransferPickupSelect';
import PhoneNumberInput from '../../form/components/specialized/PhoneNumberInput';
import { transferFormSchema } from '../schema';
import {
    selectNurseTransferById,
    storeNurseTransfer,
    updateNurseTransfer,
} from '../nurseTransfersSlice';
import { NURSE_TRANSFER_RESOURCE } from '../../api/resources';
import { 
        updateNurseDeployment,
        selectNurseDeploymentById
 } from '../../nurseDeployments/nurseDeploymentsSlice';
import { useInitialValues } from '../../form/hooks';
import { getEmptyPhoneNumber } from '../../form/components/specialized/PhoneNumbersInput';
import { selectUserIsRoot } from '../../../Modules/auth/selectors';
import { useApi } from '../../api/components/ApiProvider';
import { EMAIL_RESOURCE } from '../../api/resources';
import { formatDate, parseDate, addValueToString} from '../../../helpers/tools';

const useStyles = makeStyles((theme) => ({
    header: {
        marginTop: theme.spacing(2),
    },
}));

const TransferForm = ({ arrivalTransferId, departureTransferId, deploymentId, customerId, type, submitContainer, onDone}) => {

    const transferId = type === 'arrival' ? arrivalTransferId : departureTransferId;

    const [isActiveCommissionRelevantDataChange, setIsActiveCommissionRelevantDataChange] = useState(false);
    const [changeDataInfo, setChangeDataInfo] = useState('');
    const classes = useStyles();
    const transfer = useSelector((state) => selectNurseTransferById(state, transferId));
    const transferArrival  = useSelector((state) => selectNurseTransferById(state, arrivalTransferId));
    const transferDeparture  = useSelector((state) => selectNurseTransferById(state, departureTransferId));
    const deployment = useSelector((state) => selectNurseDeploymentById(state, deploymentId));
    const dispatch = useDispatch();
    const isRoot = useSelector(selectUserIsRoot);
    const email_api = useApi();

    //console.log(`deployment => ${transferArrival?.date} => ${transferDeparture?.date}`);
    const transferFormSchemaByType = transferFormSchema(type, transferArrival?.date, transferDeparture?.date);

    const checkForCommissionRelevantClosedData = (values, customerId, isRoot, isActiveCommissionRelevantDataChange, changeDataInfo, email_api) => {
        // commission relevant data is closed from the beginning of the 27th of the month until the end of the current month
        const today = new Date();
        const dt_billing_till = parseDate(values.date);

        // calculate the date 1 month before the billing till date
        if (dt_billing_till >= today)
        {
            // billing still in the future
            return;
        }

        // billing in the past
        const trigger_date = new Date(today);
        trigger_date.setMonth(trigger_date.getMonth() - 1);
        trigger_date.setDate(27);
        //console.log("trigger_date=>",trigger_date," billing till=>",dt_billing_till);

        //isRoot = false;
        const isSetComissionDataClosed = (trigger_date < today) && (isRoot === false);
        //console.log(isSetComissionDataClosed, isActiveCommissionRelevantDataChange);
        if (isSetComissionDataClosed && isActiveCommissionRelevantDataChange) {
            // only if the comp_date (billingFrom billingTill) > closing_end
            //if (comp_date_billing_fromtill > closing_end) return;

            // send a report mail
            const email_text = `Liebe/r Mitarbeiter/in, <br /> <br />\
            Die BK-Daten des Kunden #${customerId} wurden nach bereits abgeschlossener Provisions-Abrechnung geändert.<br /> <br />\
            ${changeDataInfo}<br /><br />\
            *** Dies ist eine automatisch generierte E-Mail und dient zur schnelleren Kommunikation. ***<br /> <br />`;

            const preppedValues = {
                from: 'buchhaltung@pflegezuhause.info',
                subject: `#${customerId} Änderung BK-Verlauf nach abgeschlossener Provisions-Abrechnung `,
                text: email_text,
                receiver: ['buchhaltung@pflegezuhause.info'],
                attachments: [],
                customer_id: customerId,
                is_premium: false,
            };
            if (process.env.REACT_APP_ENV !== 'development')
                email_api[EMAIL_RESOURCE].sendEmail(preppedValues);
            else
                console.log("development: send commission dat", preppedValues);
        }
        return false;
    };

    const handleSubmit = async (values) => {
        const prepped = {
            id: transferId,
            ...values,
            type,
        };

        /* is there a better way to do this? */
        TRANSFER_STATUS_OPTIONS.forEach(({ value: status }) => {
            if (values.status !== status) {
                delete prepped[`${status}_date`];
            }
        });

        if (values.destination === 'patient_household') {
            delete prepped.destinationName;
            delete prepped.destinationAddress;
            delete prepped.destinationZip;
            delete prepped.destinationCity;
        }

        if (!values.transferRequired) {
            delete prepped.pickup;
            delete prepped.pickupDetails;
            delete prepped.phonenumberName;
            delete prepped.phoneNumbers;
        }

        if (
            values.transferRequired &&
            prepped.phoneNumbers &&
            prepped.phoneNumbers[0].type.length === 0 &&
            prepped.phoneNumbers[0].prefix.length === 0 &&
            prepped.phoneNumbers[0].number.length === 0
        ) {
            delete prepped.phoneNumbers;
        }

        const response = await dispatch(
            transferId
                ? updateNurseTransfer(prepped)
                : storeNurseTransfer(prepped, { deploymentId })
        );

        if (prepped.date !== null && initialValues.date !== values.date)
            checkForCommissionRelevantClosedData(
                values,
                customerId,
                isRoot,
                isActiveCommissionRelevantDataChange,
                changeDataInfo,
                email_api
            );

        if (!transferId) {
            const billtype = type === 'arrival' ? 'From' : 'Till';
            const deployprep = {
                id: deploymentId,
                [`${type}TransferId`]: response.data.id,
            }
            if (prepped.date !== null) deployprep[`billing${billtype}`] = prepped.date;
            await dispatch(updateNurseDeployment(deployprep));
        } else {
            if (prepped.date !== null) {
                const billtype = type === 'arrival' ? 'From' : 'Till';
                await dispatch(
                    updateNurseDeployment({
                        id: deploymentId,
                        [`billing${billtype}`]: prepped.date,
                    })
                );
            }
        }
        onDone(response);
    };

    const isCommissionRelevantDataChange = (newvalues, initvalues) => {
        let changes = '';
        let is_changed = false;
        //console.log('isCommissionRelevantDataChange type=>', type, newvalues, initvalues, deployment);

        if (
            newvalues.date !== 'Invalid date' &&
            areDateStringIdentical(newvalues.date, initvalues.date) === false
        ) {
            //console.log("not identical => date",newvalues.date, initvalues.date);
            const billtype = type === 'arrival' ? 'vom' : 'bis';
            changes = addValueToString(
                changes,
                `Berechnung ${billtype} => ${newvalues.date}`
            );
            is_changed = true;
        }

        if (is_changed && deployment) {
            const topdata = `Berechnung von: ${deployment.billingFrom} Berechnung bis: ${deployment.billingTill}`;

            changes = `${topdata}:<br /><br />Folgende Daten wurden geändert:<br />${changes}`;
        }

        return { is_changed, changes };
    };

    const areDateStringIdentical = (date1, date2) => {
        if (date1 === date2) return true;

        const date_only_1 = formatDate(date1);
        const date_only_2 = formatDate(date2);
        return date_only_1 === date_only_2;
    }

    const handleOnChange = (newvalues, initvalues) => {
        //console.log('handleOnChange', newvalues.date, initvalues.date);
        const result = isCommissionRelevantDataChange(newvalues, initvalues);
        setIsActiveCommissionRelevantDataChange(result.is_changed);
        setChangeDataInfo(result.changes);
        if (result.is_changed) {
            //console.log("relevant changes=>","new=>", newvalues.date,"old=>", initvalues.date);
        }
    };

    const initialValues = useInitialValues(transfer, {
        status: '',
        detailsAgencyReceivedDate: null,
        detailsAgencyRequestedDate: null,
        detailsCompleteDate: null,
        detailsDoneDate: null,
        detailsSentAgencyDate: null,
        detailsSentCustomerDate: null,
        waitingForCustomerDate: null,
        date: null,
        time: '',
        costSharing: '',
        meansOfTravel: '',
        meansOfTravelDetails: '',
        transferRequired: false,
        destination: '',
        destinationName: '',
        destinationAddress: '',
        destinationZip: '',
        destinationCity: '',

        pickup: '',
        pickupDetails: '',
        phoneNumberName: '',
        phoneNumbers: (value) =>
            value && value.phoneNumbers && value.phoneNumbers.length === 1
                ? value.phoneNumbers
                : [getEmptyPhoneNumber()],
    });

    return (
        <Form
            initialValues={initialValues}
            validationSchema={transferFormSchemaByType}
            onSubmit={handleSubmit}
            subject={transfer || NURSE_TRANSFER_RESOURCE}
            onChange={handleOnChange}
        >
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TransferStatusSelect name="status" label="Status" withDate />
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h3" className={classes.header}>
                        Details
                    </Typography>
                </Grid>

                <Grid item xs={4}>
                    <DateInput name="date" label="Datum" fullWidth />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        name="time"
                        label="Uhrzeit"
                        fullWidth
                        maxLength={255}
                        maxLengthHidden
                    />
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={8}>
                    <TextInput
                        name="costSharing"
                        label="Kostenbeteiligung Transport"
                        maxLength={50}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={4}>
                    <Select
                        name="meansOfTravel"
                        label="Verkehrsmittel"
                        options={[
                            { value: '', label: 'Verkehrsmittel auswählen' },
                            ...transportationChoices.map(({ id, label }) => ({
                                value: id,
                                label,
                            })),
                        ]}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={4}>
                    <TextInput
                        name="meansOfTravelDetails"
                        label="Details"
                        fullWidth
                        maxLength={255}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Checkbox name="transferRequired" label="Transfer erforderlich" />
                </Grid>

                <Grid item xs={12}>
                    <TransferDestinationSelect
                        name="destination"
                        nameName="destinationName"
                        nameAddress="destinationAddress"
                        nameZip="destinationZip"
                        nameCity="destinationCity"
                        label="Ort"
                        customerId={customerId}
                        spacing={2}
                    />
                </Grid>

                <ConditionalField conditionFieldName="transferRequired">
                    <Grid item xs={12}>
                        <Typography variant="h3" className={classes.header}>
                            Transfer
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <TransferPickupSelect name="pickup" label="Transfer" fullWidth />
                    </Grid>

                    <Grid item xs={8}>
                        <TextInput
                            name="pickupDetails"
                            label="Details Transfer"
                            maxLength={255}
                            rows={3}
                            rowsMax={10}
                            multiline
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Typography className={classes.subheader}>Telefonnummer</Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <TextInput name="phoneNumberName" label="Name" maxLength={255} fullWidth />
                    </Grid>

                    <Grid item xs={12}>
                        <PhoneNumberInput name="phoneNumbers.0" preview selectType spacing={2} />
                    </Grid>
                </ConditionalField>
            </Grid>

            <SubmitButton container={submitContainer} />
        </Form>
    );
};

TransferForm.propTypes = {
    transferId: IdPropType,
    deploymentId: IdPropType.isRequired,
    customerId: IdPropType.isRequired,
    type: PropTypes.string.isRequired,
    submitContainer: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({})]),
    onDone: PropTypes.func,
};

TransferForm.defaultProps = {
    transferId: null,
    submitContainer: null,
    onDone: () => null,
};

export default TransferForm;
